import "../../styles/components/someBlocksStyle/BlockFifth.scss";

export default function BlockFifth() {
  const sameStyle = {
    display: "block",
    maxWidth: "100%",
  };
  return (
    <div className="blockContainerFifth">
      <div className="blockContainerFifthOne">
        {/* <div id="designBlockOne">B.ED 2022 Gallery</div> */}
        <div id="designBlockOne">B.ED Gallery</div>
        <div id="designBlockTwo">
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeLGl7NZ9y9IVToDT205NSKHsUmWYVGDE8R1osN0DQ-0x-d1g/viewform"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              src="/admissionOpen.gif"
              alt="admission"
              style={{
                display: "block",
                maxWidth: "100%",
              }}
            />
          </a>
        </div>
      </div>
      <div className="blockContainerFifthSecond">
        <div className="blockContainerFifthSecondImage">
          <img src="/bed_new_batch_2024_2.jpeg" alt="bed_new_batch_2024_2" style={sameStyle} />
        </div>
        <div className="blockContainerFifthSecondImage">
          <img src="/f1.jpg" alt="f1" style={sameStyle} />
        </div>
        {/*  */}
        <div className="blockContainerFifthSecondImage">
          <img src="/f2.jpg" alt="f2" style={sameStyle} />
        </div>
        {/*  */}
        <div className="blockContainerFifthSecondImage">
          <img src="/f3.jpg" alt="f3" style={sameStyle} />
        </div>
      </div>
    </div>
  );
}
